.lookscontainer {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color:rgb(10, 15, 41);;
    background-image: url("../Assets/abstractWallpaper.png");
  }
  .red {
    color: rgb(255, 0, 0);
  }
  .contentSection {
    width: 80%;
    margin: 0px auto;
    padding-bottom: 0;
  }
  .instructions {
    padding-top: 5vw;
  }
  .bg{
    background-color: rgb(171, 225, 248);
    opacity: 0.85;
    border-radius: 2.5rem;
    
  }
  .imageItem {
    display: flex;
    align-items: center;
    margin-bottom: 2vw;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .spacer {
    display: flex;
    align-items: center;
    height: 8vw;
  }
  .whyccsImage {
    height: 13vw;
    margin: 17px;
  }
  .heading {
    color: rgb(34, 46, 111);
    font-weight: 300;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.381);
    text-transform: uppercase;
    font-size: 2.5vw;
    font-family: Montserrat;
    margin-bottom: 20px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2.5rem;
  }
  .heading1 {
    color: rgb(34, 46, 111);
    font-weight: 300;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.381);
    font-size: 1.7vw;
    font-family: Montserrat;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    
  }
  .sub {
    color: rgb(34, 46, 111);
    font-weight: 300;
    font-size: 1.2vw;
    font-family: Montserrat;
    margin-top: 0;
    display: flex;
    align-items: left;
    justify-content: left;
    padding-left: 6rem;
    padding-right: 5rem;
    padding-bottom: 0rem;
  }
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    color: rgb(34, 46, 111);
    font-family: Montserrat;
    
  }
  .timer{
    padding-top: 0;
    
  }