* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img, a {
  -webkit-tap-highlight-color: transparent;
  /* pointer-events: none; */
  user-select: none;
}

/* button:focus{
  outline: none !important;
} */

.heroContainer {
  position: relative;
  /* background-image: url("../Assets/Background_New.jpg"); */
  background-position: 0% 0%;
  height: calc(100vh - 4vw);
  /* width: 100vw; */
  /* object-fit: contain; */
  padding-top: 6%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.heroImg {
  height: 22vw;
  bottom: 20px;
}

/*ccs logo*/
.logo {
  display: block;
  /* z-index: 1; */
  /* position: absolute;
  top: 5vw;
  left: 0; */
  width: 22vw;
  margin: 0 auto;
}


.lowerCont{
  display: flex;
  justify-content: center;
  gap: 8%;
  margin-top: 8vh;
}


.buttons {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 14vh;
}

.button {
  width: max(230px, 15vw);
  height: 60px;
  font-size: 20px;
  font-family: Montserrat;
  color: white;
  background: #274e61;
  box-shadow: 7px 11px 5px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  border: none;
  /* margin-top: 1.875em; */
  cursor: pointer;
}

.button:hover {
  background-color: #01151e;
  transform: scale(1.1);
  transition-duration: 400ms;
}

/* .registerButton {
  opacity: 0.75;
  cursor: auto;
} */

.spacer {
  display: flex;
  align-items: center;
  height: 10vw;
}

@media (max-width: 1200px) {
  .heroImg {
    height: max(200px, 30vw);
    bottom: 10vh;
  }
  .logo{
    width: max(35vw, 30vh);
    margin-top: 15%;
  }
  .lowerCont{
    flex-direction: column;
    align-items: center;
    margin-bottom: 0vh;
  }
  .buttons {
    margin-top: 6%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25%;
  }
}

@media (max-width: 768px) {
  .logo{
    width: 50vw;
    margin-top: 15%;
  }
  .lowerCont{
    flex-direction: column;
    align-items: center;
    margin-bottom: 0vh;
  }
  .buttons {
    margin-top: 6%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25%;
  }
}
