.questions {
    /* margin-left: 30px; */
    width: 80%;
    /* text-align: center; */
    margin: 60px auto;
    font-family: "poppins";
}
.quesimg{
    max-width: 75%;
}
.testQuestion {
    display: flex;
    font-size: 30px;
    margin-bottom: 20px;
}

.testOptions {
    display: flex;
    flex-direction: column;
    width: 80%;
    font-size: 24px;
    margin: 0 auto;
}

.testOption {
    padding-left: 10px;
    background-color: #F0FBFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    min-height: 50px;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
}


.testOption:has(input:checked) {
    background-color: rgba(71, 181, 255, 0.4);
}

.testOption:hover {
    background-color: rgba(71, 181, 255, 0.4);
}

.questionNumber {
    margin-right: 30px;
}