/* body {
    width: 100vw;
    min-height: 100vh;
    background-color: #DFF6FF;
} */

.testPage {
    display: flex;
    position: relative;
}

.testLeftAside {
    position: fixed;
    width: 17%;
    height: 100vh;
    background-color: #06283D;
    font-family: "poppins";
}

.testMain {
    width: 62%;
    min-height: 100vh;
    background-color: #DFF6FF;
    margin-left: 17%;
    font-family: "poppins";
}

.testHeading {
    /* font-family: Arial, Helvetica, sans-serif; */
    font-size: 40px;
    text-align: center;
    margin-bottom: 30px;
}

.testRightAside {
    position: fixed;
    right: 0;
    width: 21%;
    height: 100vh;
    background-color: #06283D;
    font-family: "poppins";
}

.testLogo {
    display: block;
    width: 85%;
    margin: 10px auto;
}

.testLeftAsideLine {

    border: none;
    background-color: white;
    height: 1px;
}

.testLeftAsideBottom {
    position: absolute;
    bottom: 20px;   
    width: 100%;
    /* marign-top: auto; */
}

.testUser {
    /* margin-bottom: 100%;
    flex-grow: 1; */
    text-align: center;
    margin-top: 30px;
}

.testName {
    color: white;
    font-size: 1.5vw;
    text-transform: uppercase;
    /* font-family: Arial, Helvetica, sans-serif; */
}

.testContact {
    /* position: absolute;
    bottom: 50px; */
    margin-bottom: 20px;
    text-align: center;
}

.testEmail {
    color: #ffffff50;
    font-size: 1.3vw;
    font-family: Arial, Helvetica, sans-serif;
}

.testLogout {
    display: block;
    margin: auto auto;
    margin-top: 20px;
    width: 100px;
    height: 40px;
    cursor: pointer;
}

.testLogout:hover {
    background-color: red;
    color: white;
}

.testTimer {
    height: 25vh;
    color: #ffffff50;
    text-align: center;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #032133;
    gap: 20px;
    
}

.testTime {
    margin: 10px auto;
    color: white;
    font-size: 3.5vw;
}

.timerText {
    font-size: 20px;
}

.testWebcam {
    position: absolute;
    width: 80%;
    margin: 20px auto;
    bottom: 0;
    left: 10%;
}

.testOption {
    display: block;
}

.submitBtn {
    display: block;
    width: 100px;
    height: 40px;
    border-radius: 15px;
    border: none;
    background-color: #06283D;
    color: white;
    margin: 20px auto;
    cursor: pointer;
}

.submitBtn:hover {
    background-color: limegreen;
}

.cheatAlert{
    /* font-family: "monospace"; */
    color: rgb(235, 86, 210);
    justify-content: center;
    padding: 2vw;
    background-color: aliceblue;
}