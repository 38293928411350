nav {
  background: rgb(12, 31, 26);
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  z-index: 10;
  background-color: #000;

  background: #030d13;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
}

.navbarContainer {
  /* position: absolute;   */
  text-decoration: none;
  font-size: 1.5vw;
  font-family: "Montserrat";
  height: max(50px, 6vw);

  opacity: 90%;
  display: flex;
  justify-content: space-between;

  z-index: 1;
  width: 100%;
}

.navLogo {
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  margin-left: 24px;
  text-decoration: none;
}

.mobileIcon {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
}

.navMenu {
  margin-right: 3%;
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.navItem {
  text-decoration: none;
  padding: 7px 15px;
  height: 5vw;
  font-family: "Montserrat";
}

.navLinks {
  /* margin-right: 2%; */
  text-decoration: none;
  font-size: 22px;
  /* color: black; */
  color: #87cfc6;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.navBtn {
  text-decoration: none;
  opacity: 100%;
  display: flex;
  align-self: center;
  margin-left: 1.5%;
  align-items: center;
  background: #87cfc6;

  border-radius: 60px;
  white-space: nowrap;
  padding: 0.500em 1.375em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  /* pointer-events: none; */
  -webkit-tap-highlight-color: transparent;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat");

.container {
  margin-left: 0;
  height: 10vh;
  color: rgb(1, 1, 5);

  opacity: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

@media (max-width: 768px) {
  .navbarContainer {
    justify-content: center;
  }
  nav{
    height: 7vh;
  }
  .navMenu {
    display: none;
  }

  .navBtn {
    font-size: 14px;
  }
}
